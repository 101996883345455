var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'home-header': true,
    'home-header-bg-white': _vm.bgWhite,
  }},[_c('div',{staticClass:"mask"}),_c('div',{class:{
      'logo-dev': _vm.agwDev,
      logo: true,
    },on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}}),_c('div',{staticClass:"nav"},[_c('navTab',{on:{"change":function($event){_vm.showBgWhite = $event}}})],1),_c('div',{staticClass:"action"},[_c('a-badge',{attrs:{"count":_vm.count}},[(_vm.isLogin)?_c('div',{staticClass:"action-item action-message",on:{"click":_vm.handleToMessage}},[_vm._v("消息")]):_vm._e()]),(_vm.isLogin)?_c('div',{staticClass:"action-item action-console",on:{"click":_vm.handleToSystem}},[_vm._v("控制台")]):_vm._e(),_c('a-badge',{attrs:{"count":_vm.buyCarNumber}},[_c('div',{staticClass:"action-item action-car",staticStyle:{"font-size":"16px"},on:{"click":function($event){return _vm.$router.push({
            path: '/supplies/supplies-car',
          })}}},[_vm._v(" 购物车 ")])]),(!_vm.isLogin)?_c('div',{staticClass:"action-item action-login",on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v("登录")]):_vm._e(),(_vm.isLogin)?_c('div',{staticClass:"action-item action-user",on:{"click":function($event){return _vm.$refs.drawer.show()}}},[_c('div',{staticClass:"user-icon"}),_c('div',{staticClass:"user-content"},[_vm._v("我的账号")])]):_vm._e(),(!_vm.isLogin)?_c('div',{staticClass:"action-item action-enroll",on:{"click":function($event){return _vm.$router.push({ name: 'register' })}}},[_vm._v("免费注册")]):_vm._e()],1),_c('Drawer',{ref:"drawer"}),_c('DrawerCar',{ref:"drawerCar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }