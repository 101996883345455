<template>
  <div class="home-footer-nav">
    <div class="footer-content">
      <div class="footer-nav">
        <div class="nav-item" v-for="item in navList" :key="item.label" @click="handleClick(item.name)">
          {{ item.label }}
        </div>
      </div>
      <div class="footer-link">
        <div class="link-label">友情链接：</div>
        <div class="link-content">
          <a class="link-item" v-for="item in linkList" :key="item.link" :href="item.link" target="_blank">{{
            item.label
          }}</a>
        </div>
      </div>
      <div class="footer-address">
        <div class="left">
          <div class="phone">咨询热线：400-625-9698</div>
          <div class="work-time">工作时间：工作日：9：00—17：30</div>
        </div>
        <div class="right">
          <div class="code-item">
            <img src="../../../assets/home/fwh.jpg" class="code" alt />
            <div class="code-label">服务号</div>
          </div>
          <div class="code-item">
            <img src="../../../assets/home/dyh.jpg" class="code" alt />
            <div class="code-label">订阅号</div>
          </div>
          <div class="code-item" v-if="!isPreview">
            <img src="../../../assets/home/app-down.png" class="code" alt />
            <div class="code-label">APP下载</div>
          </div>
          <div class="code-item" v-else>
            <img src="../../../assets/home/app-down-prod.png" class="code" alt />
            <div class="code-label">APP下载</div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-info">
      <div class="company">Copyright © 鼎基智能云平台有限公司</div>
      <div class="company-provider">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #999999;">鲁ICP备2022020246号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
const notPage = []

export default {
  name: 'HomeFooter',
  data () {
    return {
      navList: [
        {
          label: '首页',
          name: 'home'
        },
        {
          label: '找活',
          name: 'biddingList'
        },
        {
          label: '找人',
          name: 'FindSomeone'
        },
        {
          label: '找物',
          name: 'suppliesShop'
        },
        {
          label: '找智',
          name: 'materialShop'
        },
        {
          label: '找钱',
          name: 'Finance'
        }
      ],
      linkList: [
        {
          label: '鼎基电力',
          link: 'http://www.jldjdl.com/Default.aspx'
        },
        {
          label: '东电云平台',
          link: 'http://m.ddypt.com/zhaobiao/'
        },
        {
          label: '中国大地保险',
          link: 'http://www.ccic-net.com.cn/'
        }
      ],
      isPreview: process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    handleClick (name) {
      if (notPage.includes(name)) {
        this.$message.error('正在加急开发中!')
      } else if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    }
  }
}
</script>

<style lang="less">
.home-footer-nav {
  width: 100%;
  background: #222222;
  .footer-content {
    width: 1200px;
    margin: 0 auto;
  }
  .footer-nav {
    height: 122px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    .nav-item {
      margin-right: 80px;
      color: #ffffff;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #1677ff;
      }
    }
  }
  .footer-link {
    display: flex;
    align-items: center;
    height: 120px;
    justify-content: center;
    .link-label {
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
    }
    .link-content {
      display: flex;
      align-items: center;
      .link-item {
        margin-left: 20px;
        font-weight: 300;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
  .footer-address {
    display: flex;
    justify-content: space-between;
    .phone {
      font-weight: 700;
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .work-time {
      font-weight: 300;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
    .right {
      display: flex;
      align-items: center;
      .code-item {
        margin-left: 30px;
        .code {
          width: 100px;
          height: 100px;
          vertical-align: middle;
        }
        .code-label {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          margin-top: 16px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .company-info {
    margin-top: 65px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
    background: #353535;
    .company {
      margin-right: 50px;
    }
  }
}
</style>
