<template>
  <div id="userLayout" :class="['user-layout-wrapper']">
    <div class="page__header">
      <div class="page__logo">
        <router-link to="/">
          <div class="header__logo">
            <img :src="logoPath" />
          </div>
        </router-link>
      </div>
    </div>
    <div class="page__body">
      <router-view></router-view>
    </div>
    <div class="bottom-p">
      <P
      >Copyright © 鼎基智能云平台有限公司
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="color: rgba(0, 0, 0, 0.65)"
        >鲁ICP备2022020246号-1</a
        >
      </P>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLayout',
  components: {},
  data () {
    return {
      currentPath: '',
      logoPath: ''
    }
  },
  mounted () {
    const logoName = process.env.NODE_ENV === 'development' ? 'logo-dev' : 'logo'
    this.logoPath = require(`@/assets/denglu/${logoName}.png`)
    this.currentPath = this.$route.path
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>
<style>
body {
  background: #f4f4f8;
}
</style>
<style lang="less">
#userLayout.user-layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .page__header {
    width: 100%;
    min-height: 99px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    opacity: 1;
    .page__logo {
      width: 1400px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      img {
        width: 260px;
        height: 55.87px;
      }
    }
  }
  .page__body {
    flex: 1;
    position: relative;
    background: #f4f4f8;
  }
  .bottom-p {
    min-height: 80px;
    text-align: center;
    font-size: 16px;
    background: #f4f4f8;
  }
}
</style>
